//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import { couponMixin } from "@/mixins/coupon";
import { CouponGet } from "@/components";
import { ctrlAccount, ctrlCpg } from "@/controller";
export default {
    mixins: [couponMixin],
    components: {
        CouponGet,
    },
    data() {
        return {
            cpnInfo: {},
        };
    },
    computed: {
        ...mapState({
            pages: function(state) {
                return state.page;
            },
            list(state) {
                return state.page.pageInfo.List;
            },
        }),
    },
    watch: {
        "pages.pageInfo": {
            handler(newVal) {
                if (this.Common.isExist(this.pages.pageInfo)) {
                    console.log(this.pages.pageInfo);
                    if (this.$route.params.fun == "promo") {
                        document.title = this.pages.pageInfo.CpnInfo.title;
                        this.isLogin = ctrlAccount.chkLogin();
                        if (this.isLogin) {
                            this.couponList = this.pages.pageInfo.List.find((item) => item.name == "Coupon").list;
                            this.Common.isExist(this.couponList) && this.mix_uncollectCoupon(this.couponList, "getPageInfo");
                        }
                    } else if (this.$route.params.fun == "campaign") {
                        document.title = this.pages.pageInfo.CpgInfo.title;
                    }
                }
            },
            deep: true,
        },
    },
    created() {
        //獲取訂單來源
        this.setCorpSrc();
        //領券分享成功
        if (this.Common.isExist(this.$route.query.t)) {
            this.mix_collectCoupon(this.$route.query.extra, 1, "getPageInfo");
        }
    },
    methods: {
        //分享
        async shareCollect(item) {
            let obj = {
                cpnNbr: item.cpnNbr,
            };
            await this.mix_getShareUrl(obj);
        },
        //獲取訂單來源
        async setCorpSrc() {
            const corpNbr = this.$route.params.corpNbr;
            if (corpNbr == "index") return;
            const data = await ctrlCpg.setCorpSrc({ corpType: "co", corpNbr });
        },
    },
};
